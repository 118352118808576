import React from 'react'
import {Container, LoginForm} from 'src/components'
import {App} from "src/layouts";

export default function Login() {
    return (
        <Container size={'small'}>
            <LoginForm/>
        </Container>
    )
}


Login.getLayout = function getLayout(page: React.ReactElement) {
    return (
        <App
            options={{redirectIfAuthenticated: `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/profile`}}
            hideBetting
        >{page}</App>
    )
}